
      const baseUrl = import.meta.env.PLAUSIBLE_BASE_URL;
      const dataDomain = import.meta.env.PLAUSIBLE_WEB_DATA_DOMAIN;
      if (baseUrl && dataDomain) {
        const script = document.createElement('script');
        script.defer = true;
        script.setAttribute('data-domain', dataDomain);
        script.setAttribute('data-api', new URL('./api/event', baseUrl).href);
        script.src = new URL('./js/script.js', baseUrl).href;
        document.head.appendChild(script);
      }
    